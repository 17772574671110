import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ReadMoreCard = props => {
  let trimmedString = props.description.substr(0, 100)
  trimmedString = trimmedString
    .substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))
    .concat("...")
  const [description, setDescription] = useState(trimmedString)
  const [readMore, setReadMore] = useState(true)
  const handleClick = () => {
    setDescription(prevState => {
      if (prevState.length < 120) {
        setReadMore(false)
        return props.description
      }
      setReadMore(true)
      return trimmedString
    })
  }
  return (
    <div className="relative flex flex-col items-center px-8 py-12 text-lg bg-white rounded-lg shadow-lg transition hover:shadow-xl transform duration-300 ease-in-out hover:-translate-y-1">
      <dt className="self-start">
        <div className="absolute flex items-center justify-center w-12 h-12 pb-4 text-yellow">
          <FontAwesomeIcon icon={props.icon} className="w-24 h-24 text-xl" />
        </div>
        <p className="ml-12 text-lg font-medium text-blue leading-6">
          {props.name}
        </p>
      </dt>
      <dd className="mt-2 ml-12 text-base text-dark">{description}</dd>
      <button
        className="absolute px-1 border-2 text-sm bottom-4 hover:border-blue transition duration-300 ease-out hover:text-blue border-yellow text-blue"
        onClick={handleClick}
      >
        {readMore ? "Read More" : "Read Less"}
      </button>
    </div>
  )
}

export default ReadMoreCard
