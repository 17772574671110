import React from "react"
import { Link } from "gatsby"

const posts = [
  {
    header: "Fujitsu",
    title:
      "Ductless Mini-Split Systems offer whisper-quiet, energy-efficient heating and cooling homes. These sleek units require no ductwork and allow for flexible, room-by-room temperature control.",
  },
  {
    header: "Bryant",
    title:
      "Offering the highest efficiency line system for total home air conditioning, Bryant also provides furnaces that bring unprecedented comfort and efficiency.",
  },
  {
    header: "Ruud",
    title:
      "These units offer comfort, energy conservation and dependability for single, multi-family and light commercial applications. Their gas furnaces are designed for installation in closets, alcoves, utility rooms, or attics.",
  },
]

export default function Cards() {
  return (
    <div className="relative px-4 pt-8 pb-20 sm:px-6">
      <div className="relative mx-auto max-w-6xl">
        <div className="max-w-lg mx-auto grid gap-8 md:gap-16 lg:grid-cols-3 lg:max-w-none">
          {posts.map(post => (
            <Link
              to={"/" + post.header.toLowerCase()}
              key={post.title}
              className="flex flex-col overflow-hidden border border-gray-100 rounded-lg shadow-2xl hover:-translate-y-2 transform transition duration-200 linear"
            >
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-48 text-center bg-white text-red">
                  <h2 className="text-4xl font-extrabold tracking-tight text-red sm:text-5xl">
                    {post.header}
                  </h2>
                </div>
              </div>
              <div className="flex flex-col justify-between flex-1 p-6 text-light bg-blue">
                <div className="flex-1">
                  <a href={post.href} className="block mt-2">
                    <p className="mt-3 text-base">{post.title}</p>
                  </a>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}
