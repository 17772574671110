import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import FeatureGrid from "../components/Feature2x2Grid"
import Cards from "../components/CardColumns3"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <FeatureGrid />
    <Cards />
  </Layout>
)

export default IndexPage
