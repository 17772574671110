import React from "react"
import {
  faWind,
  faConciergeBell,
  faHome,
  faTools,
  faFan,
  faHammer,
  faClock,
} from "@fortawesome/free-solid-svg-icons"
import ReadMoreCard from "./ReadMoreCard"

const features = [
  {
    name: "New Installations and Replacements",
    description:
      "Whether you need a new installation of an air conditioner or heating unit, or want to upgrade to a more energy-efficient model, we at Alpine Air Conditioning and Heating can solve your problem. We can install or replace a central air system with the appropriate duct work, heat pump, furnace, mini split, or even a wall heater. Working with you, we will determine the need, consider the cost, and recommend the best option for your situation.",
    icon: faWind,
  },
  {
    name: "Residential and Commercial",
    description:
      "Achieving comfort and efficiency is the goal for both residential and commercial projects. But, there may be many differences in the way that goal should be reached. Our Alpine Air Conditioning and Heating specialists are equally proficient at evaluating needs, and providing advice, materials, and service for both residential and commercial projects. Whether for your home or place of work, we are committed to providing you with the highest quality service anywhere.",
    icon: faConciergeBell,
  },
  {
    name: "New Construction",
    description:
      "Building that dream home or new work place? You have, no doubt, invested a great deal of time, thought, emotion, and money in getting to this point. You want everything, including the HVAC system to be perfectly suited to your new building. We would like to help you plan and install the appropriate HVAC system for your new construction. We’ll help you evaluate the unique HVAC needs of your building, choose the best make and model unit to meet those needs, and plan the most appropriate delivery system. Then, our expert installers will make sure it all comes together to complement the rest of your building’s design.",
    icon: faHome,
  },
  {
    name: "Service and Repairs on all Makes and Models",
    description:
      "Air conditioners and heaters only fail when you need them. When that happens, you will be glad to know Alpine Air Conditioning and Heating staff are trained service and repair specialists in virtually all major HVAC system makes and models. Our repair personnel are highly skilled when it comes to repairing your Bryant, Fujitsu, Trane, Carrier, or Coleman product, as well as being knowledgeable in the operation of other brands. When you are in need, give us a call and we’ll do our very best to get your environment restored to the level of air comfort you have come to expect.",
    icon: faTools,
  },
  {
    name: "Indoor Air Quality",
    description:
      "In today's environment, pollution and bad gases are everywhere. That's why we at Alpine Air Conditioning and Heating make sure that when you breathe indoors, the air you're breathing is safe. Cleaning and filtering is the key to breathing air that is free of particles, contaminates, gasses, and vapors.",
    icon: faFan,
  },
  {
    name: "Custom Sheet Metal and Flashings",
    description:
      "Did you know that air conditioning and heating systems installers are required to fabricate custom sheet metal pieces and flashings to use in the installation process? This is a specialized skill calling for specialized tools. Those skills and tools can be used to fashion custom products like chimney caps, roof gutters, industrial backsplashes, and flashings for the installation of products like skylights. We use our skills and tools for those types of projects. We can customize metal fabrication specialty items from sheet metal, stainless steel, brass and copper. If you have a specific need, we’d like to help you with the same quality service we strive for in our HVAC department.",
    icon: faHammer,
  },
  {
    name: "Alpine Maintenance Program",
    description:
      "When you sign up for our Alpine Maintenance Program (AMP), we will come out and clean your unit twice a year. During the summer, we will clean your A/C unit, perform a 23-point inspection, and change your filter. When winter is approaching, we’ll clean your furnace, perform a 21-point inspection, and change your filter. Purchase of the AMP also entitles you to a 10% discount on any parts you may need to replace, and earn you priority scheduling. Right now we have a special rate for the Alpine Maintenance Program: Cleaning, inspection, filter, service, and priority scheduling for just $200 per system. That’s a bargain you don’t want to pass up!",
    icon: faClock,
  },
]

export default function Feature2x2Grid(props) {
  return (
    <div className="py-12 bg-light">
      <div className="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h1 className="mt-2 text-3xl font-extrabold tracking-tight text-blue leading-8 sm:text-4xl">
            Our HVAC Services
          </h1>
          <h3 className="max-w-2xl mt-4 text-xl font-bold text-blue lg:mx-auto">
            Serving Alpine, Amador, Calaveras, & El Dorado Counties
          </h3>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-20 md:gap-y-10">
            {features.map(feature => (
              <ReadMoreCard
                key={feature.name}
                description={feature.description}
                name={feature.name}
                icon={feature.icon}
              />
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
